import { template as template_39307a79755d4e48b548a6cbb5f0570f } from "@ember/template-compiler";
const FKControlMenuContainer = template_39307a79755d4e48b548a6cbb5f0570f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
