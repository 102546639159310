import { template as template_f1f28308e074431b98e588f84d67e541 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f1f28308e074431b98e588f84d67e541(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
